import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { TestFeeStatus, invoiceTypesObj, enrollPrograms, paymentStatus, STATUS_OBJECT, REQUEST_STATUS } from '../dummy/stauts';
import { TestOption } from '../interfaces/testing';
import * as moment from "moment";
import { Pages } from '../dummy/pages';
import { ConvertService } from 'src/app/shared/services/convert.service';
import * as firebase from 'firebase/app';
import { IChartAccount } from '../interfaces/company';
import { SCHOOL } from '../dummy/config';
import { MOVEMENT_STATUS_DATA } from '../dummy/data';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  prefix = "admin_";
  storeKey: any = SCHOOL.key;

  constructor(
    private db: AngularFirestore
  ) { }
  batchStudentRef(key) {
    return this.db.collection("institute_training_level_batch").doc(key).collection("students")
  }

  getStudentByGradeRef(termKey, campusKey, levelId) {
    return this.db.collection('academic_year').doc(termKey).collection('admission', ref => ref.where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelId)
      .where("isHaveBatch", "==", true))
  }

  batchConfirmLevelBatchKeyRef(levelKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
    )
  }
  autoSearchBatchLevelRef(field: string, text: any, schoolKey, yearKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("institute_training_level_batch", ref => ref
        .where('schoolKey', '==', schoolKey)
        .where('academicYear.key', '==', yearKey)
        .where('campus.key', '==', campusKey)
        .where(field, '>=', search)
        .limit(15)
        .orderBy(field)
      )
    }
    return this.db.collection("institute_training_level_batch", ref => ref
      .where('schoolKey', '==', schoolKey)
      .where('academicYear.key', '==', yearKey)
      .where('campus.key', '==', campusKey)
      .limit(15)
      .orderBy("name"))
  }
  studentKeyRef(key) {
    return this.db.collection("students").doc<any>(key);
  }
  collectionSAttendence(administionKey, studentKey, dateKey) {
    return this.db.collection('academics_major_admission').doc(administionKey).collection('student_attendance_statistic').doc(`${dateKey}`)
  }

  gradeSheetMovementRef() {
    return this.firestore().collection('grade_sheet_movements')
  }

  subjectSearchRef(keyword) {
    return this.db.collection<any>("subjects", ref =>
      ref
        .where("name", ">=", keyword)
        .orderBy("name")
        .limit(25)
    );
  }
  collectionRef(collectionName,ref?: QueryFn) {
    return this.db.collection(collectionName, ref);
  }

  collectionFireRef(collectionName) {
    return this.firestore().collection(collectionName,);
  }

  batchProgramRef(campusKey, termKey, programKey, shiftKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.program.key", "==", programKey)
      .where("academicYear.key", "==", termKey)
      .where("campus.key", "==", campusKey)
      .where("shift.key", "==", shiftKey)
      .orderBy('level.order'))
  }

  generalJournalRef(lastVisible?: any) {
    return this.db.collection('general_journals', ref => {
      let condition = ref.where('closing', '==', false)
        .where('storeKey', '==', this.storeKey)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['journalDate'])
      }
      return condition.orderBy('journalDate', 'desc').orderBy('lineNo').limit(Pages.size)
    })
  }

  pettyCashRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef().collection('petty_cash', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  fixedAssetsRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef().collection('fixed_assets', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  journalEntryRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef().collection('journal_entry', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  displayName(item: any): string {
    return item ? item.name : item;
  }

  displayFullName(item: any): string {
    return item ? item.full_name : item;
  }

  sysAccountTypesRef() {
    return this.db.collection('admin_sys_account_types', ref => ref.orderBy('order'))
  }

  academicYearRefCollection() {
    return this.db.collection("academic_year");
  }
  collection(name) {
    return this.db.collection(name)
  }


  storeRef() {
    return this.db.collection('stores').doc(this.storeKey)
  }

  firestoreRef() {
    return this.db
  }
  userAccountDoc(uid: string) {
    return this.db.collection(this.prefix + "sys_accounts").doc<any>(uid);
  }

  sysAccounting() {
    return this.firestore().collection(this.prefix + "sys_account_types");
  }

  sysConfig() {
    return this.firestore().collection(this.prefix + "sys_configs");
  }

  chartAccountFireRef() {
    return this.schoolFireRef().doc(this.storeKey).collection("chart_of_accounts");
  }

  schoolsRef() {
    return this.db.collection('schools').doc(this.storeKey);
  }

  subAccountByAccountTypeRef(accountTypeKey) {
    return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => {
      if (accountTypeKey) {
        return ref.where('accountType.key', '==', accountTypeKey).orderBy("order").orderBy("node")
      }
    })
  }

  subAccountRef(account) {
    return this.schoolFireRef().doc(this.storeKey).collection('chart_of_accounts').where('subAccount.key', '==', account.key)
  }

  subOfChartOfAccountRef(account) {
    return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => ref.where('subAccount.key', '==', account.key))
  }

  subAccountOfRef() {
    return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => ref.where('level', '==', 0).orderBy('index', 'desc').limit(1))
  }
  appSchoolProgramRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_school_programs", ref => ref.orderBy("page_key", "desc"));
  }
  appAnnouncementsRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_announcements", ref => ref.orderBy("page_key", "desc"));
  }
  appVideoRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_videos", ref => ref.orderBy("page_key", "desc"));
  }
  appManagementTeamRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_management_team", ref => ref.orderBy("page_key"));
  }
  appNewsRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_news", ref => ref.orderBy("page_key", "desc"));
  }

  appPartnerRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_partners", ref => ref.orderBy("page_key", "desc"));
  }
  appEventRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_events", ref => ref.orderBy("page_key", "desc"));
  }
  sysAccountDetailRef(key) {
    return this.db
      .collection(this.prefix + "sys_account_types")
      .doc(key)
      .collection("details");
  }
  listRef(collectionName, orderBy, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => ref.orderBy(orderBy))
  }
  chartAccountRef() {
    return this.db.collection(this.prefix + "chart_of_accounts", ref =>
      ref.orderBy("order").orderBy("node")
    );
  }
  balanceOfAccountRef(branch: any, account: any) {
    return this.db.collection<any>(this.prefix + "ledgers", ref =>
      ref
        .where("branch.key", "==", branch.key)
        .where("order", "==", account.order)
        .where("parent", "==", account.parent)
        .where("node", ">", account.node)
        .orderBy("node")
    );
  }

  chartAccountIndexRef() {
    return this.db.collection(this.prefix + "chart_of_accounts", ref => ref.orderBy("index", "desc").limit(1));
  }

  sysAccountingRef() {
    return this.db.collection(this.prefix + "sys_account_types", ref =>
      ref.orderBy("order")
    );
  }
  sysConfigDocRef() {
    return this.db.collection(this.prefix + "sys_configs").doc("accounts");
  }

  sysConfigSettingRef() {
    return this.firestore().collection(this.prefix + "sys_configs").doc("settings");
  }

  sysSettings() {
    return this.db.collection(this.prefix + "sys_configs").doc("settings");
  }

  chartOfAccountsFireRef() {
    return this.schoolFireRef().doc(this.storeKey).collection("chart_of_accounts");
  }

  ledgerRef(branch: any, period: any) {
    return this.db.collection<any>(this.prefix + "ledger_movement").doc(period).collection("ending_balances", ref => ref.where("branch.key", "==", branch))
  }

  chartOfAccountsRef(field, type: any) {
    return this.schoolDocRef().collection<IChartAccount>("chart_of_accounts",
      ref => ref.where(field, "==", type)
        .orderBy("order")
        .orderBy("node")
    );
  }

  chartOfAccountCheckNameRef(name) {
    return this.storeRef().collection<IChartAccount>("chart_of_accounts", ref => ref.where('name', '==', name).limit(1))
  }

  chartOfAccountRef() {
    return this.storeRef().collection<IChartAccount>("chart_of_accounts",
      ref => ref.orderBy("order").orderBy("node")
    );
  }
  bookstoreRef(collectionName, lastVisible: any, displayStatusKey: any, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.where("organizationKey", "==", null)
      if (displayStatusKey === STATUS_OBJECT.deleted.key) {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", displayStatusKey);
      }
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc")
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      return condition.limit(Pages.size)
    })
  }
  batchCollectionRef() {
    return this.db.firestore;
  }
  booksRef() {
    return this.db.collection('book_store_books')
  }

  bookClubRef() {
    return this.db.collection('book_store_books', ref => ref.where('bookClub', '==', true))
  }
  genresRef() {
    return this.db.collection('book_store_genres')
  }

  sectionsRef() {
    return this.db.collection('book_store_sections')
  }

  authorRef() {
    return this.db.collection('book_store_authors')
  }

  subjectsRef(lastVisible?: any) {
    return this.db.collection('subjects', ref => {
      let condition = ref.orderBy('name_en')
        .limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['name_en'])
      }
      return condition;
    })
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemNameEn(item: any): string {
    return item ? item.name_en : item;
  }

  displayItemSubjectNameEn(item: any): string {
    return item ? item.subject.name_en : item;
  }

  gradeSystemAnnualRef(programKey?: any) {
    return this.db.collection("grading_annual_system", ref => {
      let condition = ref.orderBy("order");
      if (programKey) {
        return ref.where('program.key', '==', programKey)
      }
      return condition;
    })
  }

  gradingSystemRef(ref?) {
    return this.db.collection('grading_program_subject_scales', ref)
  }

  gradeSystemSubjectRef(gradingSubjectKey?, gradeTypeKey?: any) {
    return this.db.collection("grading_program_subject_scales", ref => {
      let condition = ref.orderBy("order");
      if (gradingSubjectKey && gradeTypeKey) {
        return ref.where('gradeSubjectKey', '==', gradingSubjectKey)
          .where('gradingType.key', '==', gradeTypeKey)
          .orderBy('order')
      }
      return condition;
    });
  }

  gradingSubjectRef(gradeLevelKey?) {
    return this.db.collection("grading_program_subjects", ref => {
      let condition = ref.orderBy("subject.name_en");
      if (gradeLevelKey) {
        return ref.where('gradeLevelKey', '==', gradeLevelKey)
      }
      return condition;
    });
  }

  gradingLevel(programKey?) {
    return this.db.collection("grading_program_levels", ref => {
      let condition = ref.orderBy("level.order");
      if (programKey) {
        return ref.where('program.key', '==', programKey)
      }
      return condition;
    });
  }

  getCashierDocRef() {
    return this.db.collection("users", ref => ref.orderBy("create_date", "desc"));
  }

  fecthUserCallback() {
    return this.db.collection("users", ref => ref.where("status.key", "==", 1).limit(15));
  }

  autoSearchUserRef(field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("users", ref => ref
        .where('status.key', '==', 1)
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("users", ref => ref
      .where('status.key', '==', 1)
      .limit(15)
      .orderBy("update_date", "desc"))
  }


  fecthEmployeesCallback() {
    return this.db.collection("employees", ref => ref.where("status.key", "==", 1).limit(15).orderBy("update_date", "desc"));
  }

  autoSearchEmployeesRef(field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("employees", ref => ref
        .where('status.key', '==', 1)
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("employees", ref => ref
      .where('status.key', '==', 1)
      .limit(15)
      .orderBy("update_date", "desc"))
  }



  fecthAllEmployeesCallback() {
    return this.db.collection("employees", ref => ref.limit(15).orderBy("update_date", "desc"));
  }

  autoSearchAllEmployeesRef(field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("employees", ref => ref
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("employees", ref => ref
      .limit(15)
      .orderBy("update_date", "desc"))
  }

  userProfileRef() {
    return this.db.collection("user_profiles")
  }
  kgeTestRef(gradeKey?: any, type?: any) {
    if (type) {
      return this.db.collection("testing_kge", ref => ref
        .where("exam_type.key", "==", type.key)
        .where("programLevelKey", "==", gradeKey)
        .orderBy("created_at"));
    }
    return this.db.collection("testing_kge", ref => ref.orderBy("created_at"));
  }
  kgeQuestionRef(questionKey) {
    return this.db.collection("testing_kge").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  placementTestRef() {
    return this.db.collection('placement_test')
  }
  generalPlacementTestRef() {
    return this.db.collection('general_placement_test', ref => ref.orderBy('page_key'))
  }
  gepRef(type: any) {
    return this.db.collection("testing_gep", ref => ref.where("type.key", "==", type.key).orderBy("created_at"));
  }
  teacherRef() {
    return this.db.collection("instructors");
  }
  kindergartenTestRef() {
    return this.db.collection("testing_kindergarten", ref => ref.orderBy("created_at"));
  }
  gepQuestionRef(questionKey) {
    return this.db.collection("testing_gep").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  kindergartenQuestionRef(questionKey) {
    return this.db.collection("testing_kindergarten").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  userAccountRef() {
    return this.db.collection("users");
  }
  englishTeacherRef() {
    return this.db.collection("employees");
  }
  employeeBySchoolRef(schoolKey) {
    return this.db.collection("employees", ref => ref.where("schoolKey", "==", schoolKey).limit(100));
  }
  kidMcqQuestionRef(questionKey) {
    return this.db.collection("testing_kindergarten").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }

  autoCollectionRef(collection) {
    return this.db.collection(collection, ref => ref.where('status.key', '==', 1).limit(Pages.size));
  }

  autoFilterCollectionRef(collectionName: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("status.key", "==", 1)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field, "desc")
          .limit(Pages.size)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("status.key", "==", 1)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  studentAdmissionDocRef(key) {
    return this.db.collection("academics_major_admission").doc(key);
  }

  studentAdmissionByStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }

  serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  settingFireStoreFire() {
    return this.firestore().collection("testing_options").doc("general");
  }

  usersRef() {
    return this.db.collection('users')
  }

  scholarshipDBByStudentRef(studentKey: string) {
    return this.db.collection('scholarships', ref => ref
      .where("student.key", "==", studentKey)
    );
  }

  employeeSearchDocsRef(search) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .orderBy("page_key")
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  scholarshipFireRef() {
    return this.firestore().collection('scholarships');
  }
  scholarshipMovementFireRef() {
    return this.firestore().collection('scholarship_movement');
  }
  employeesSearchRef(field: string, search: string) {
    if (!search) {
      return this.db.collection("employees", ref =>
        ref
          .orderBy(field, 'desc')
          .limit(Pages.size)
      );
    }

    return this.db.collection("employees", ref =>
      ref
        .where(field, ">=", search)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  DepartmentArrayRef() {
    return this.db.collection<any>("departments", ref => ref.orderBy("name").limit(30));
  }

  departmentsRef() {
    return this.db.collection<any>("departments", ref => ref.orderBy("name"));
  }
  titlesRef() {
    return this.db.collection<any>("titles", ref => ref.orderBy("name"));
  }
  getemployeeFetchRef(t, lastVisible: any) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 1)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 2)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }

  }
  getemployeeClassRef(tkey, inskey) {
    return this.db.collection<any>("academic_year").doc(tkey).collection('schedules', ref => ref.where('instructor.key', '==', inskey));
  }
  getemployeeExperiencesRef(key) {
    return this.db.collection("employees_experiences", ref => ref.where('employee.key', '==', key));
  }

  employmentContractRef(key: any) {
    return this.db.collection('employees').doc(key).collection("employees_contracts", ref => ref.orderBy('created_at', 'desc'));
  }

  getinstructorInsRef(ins, t) {
    const em = ins + '.employment_type.key';
    const inst = ins + '.institute.key';
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where(inst, '==', ins).where(em, '==', 1));
    } else {
      return this.db.collection("employees", ref => ref.where(inst, '==', ins).where(em, '==', 2));
    }
  }
  getinstructorRef(t) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where('academicRef.employment_type.key', '==', '1'));
    } else {
      return this.db.collection("employees", ref => ref.where('academicRef.employment_type.key', '==', '2'));
    }
  }
  getOneemployeeEmploymentRef(key) {
    return this.db.collection("employees_contracts", ref => ref.where('employee.key', '==', key).orderBy('start_date_key', 'asc').limit(1));
  }
  employeeFetchRefSc(lastVisible: any) {
    return this.db.collection("employees", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }
  employeeListingRef(code: string) {
    return this.db.collection("employees", ref =>
      ref
        .where("code", "==", code)
        .orderBy("page_key", "desc")
        .limit(Pages.size)
    );
  }

  sysSettingRef() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }

  employeeFireStore() {
    return this.firestore().collection("employees");
  }
  employeeCollection() {
    return this.db.collection("employees", ref => ref.orderBy("full_name"));
  }

  instructorsFireStore() {
    return this.firestore().collection("instructors");
  }

  instructorsRateFireStore() {
    return this.firestore().collection("instructor_rates");
  }

  instructorsmayjorRef() {
    return this.db.collection("instructor_mayjor");
  }
  EmployeeEducationsRef() {
    return this.db.collection("employees_educations");
  }
  EmployeeContactsRef() {
    return this.db.collection("employees_contacts");
  }
  EmployeeExperiencesRef() {
    return this.db.collection("employees_experiences");
  }

  employeeEmploymentFireRef() {
    return this.firestore().collection("employees_contracts");
  }

  instituteFireRef() {
    return this.firestore().collection("institutes");
  }

  instituteMemberFireRef() {
    return this.firestore().collection("institutes_members");
  }

  facultyMemberFireRef() {
    return this.firestore().collection("faculties_members");
  }

  positionRef() {
    return this.db.collection("positions");
  }

  positionByLevelRef(levelKey) {
    return this.db.collection("positions");
  }

  nationalityArrayRef() {
    return this.db.collection<any>("nationality");
  }
  countryArrayRef() {
    return this.db.collection<any>("location_countries");
  }
  instructorFireRef() {
    return this.firestore().collection("instructors");
  }

  instructorContractFireRef() {
    return this.firestore().collection("instructor_contract_movement");
  }

  instituteContractFireRef() {
    return this.firestore().collection("institute_contract_movement");
  }
  facultyFireRef() {
    return this.firestore().collection("faculties");
  }
  EmployeeEmploymentRef() {
    return this.db.collection("employees_contracts");
  }

  cityArrayRef(key) {
    return this.db.collection<any>("location_cities", ref => ref.where('country.key', '==', key));
  }
  districtArrayRef(key) {
    return this.db.collection<any>("location_districts", ref => ref.where('city.key', '==', key));
  }
  getemployeeListingRef(t, code: string) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 1)
          .where("code", "==", code)
          .orderBy("page_key", "desc")
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 2)
          .where("code", "==", code)
          .orderBy("page_key", "desc")
          .limit(Pages.size)
      );
    }

  }
  getInstructorRateRef(key) {
    return this.db.collection<any>("instructor_rates", ref => ref.where('employee.key', '==', key));
  }

  getemployeeSalaryRef(key) {
    return this.db.collection<any>("employees").doc(key).collection('employees_contracts');
  }

  getemployeeEducationsRef(key) {
    return this.db.collection("employees_educations", ref => ref.where('employee.key', '==', key));
  }
  getemployeeProfileRef() {
    return this.db.collection("employees", ref => ref.orderBy('create_date').limit(50));
  }

  lazyEmployeesRef(lastVisible: any, search, filter, statusKey) {
    return this.db.collection<any>("employees", ref => {
      let condition = ref
        .orderBy("update_date", "desc")
        .limit(Pages.size)
      if (statusKey) {
        condition = condition.where("status.key", "==", statusKey)
      }
      if (search) {
        const txt = ConvertService.toCapitalize(search)
        condition = condition.where('keywords', 'array-contains', txt)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }
  getemployeeRef(t) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where('employmentRef.employment_type.key', '==', 1).limit(Pages.size));
    } else {
      return this.db.collection("employees", ref => ref.where('employmentRef.employment_type.key', '==', 2).limit(Pages.size));
    }
  }
  getemployeeDocRef(key) {
    return this.db.collection("employees").doc(key);
  }

  getRelatedEmployeeDocRef(collection, key) {
    return this.db.collection(collection, ref => ref.where('employee.key', '==', key))
  }

  instructorContractDataRef(employeeKey) {
    return this.db.collection("instructor_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey));
  }

  instituteContractRef(employeeKey, instituteKey) {
    return this.db.collection("institute_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey)
      .where("institute.key", "==", instituteKey)
      .orderBy("create_date", "desc"));
  }
  instructorContractRef(employeeKey, type) {
    return this.db.collection("instructor_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey)
      .where("id", "==", type)
      .orderBy("create_date", "desc"));
  }
  employeereportFetchRef(tabkey, timekey, type) {
    let ref = this.firestore().collection('employees')
    const cdate = moment(new Date()).format('YYYYMMDD');
    if (tabkey == 'staff') {
      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref
            .where('employmentRef.end_date_key', '>', 0)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.end_date_key', '>', 0)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.end_date_key', '>', 0)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where('employmentRef.employment_type.key', '==', 1)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.employment_type.key', '==', 1)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.employment_type.key', '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where('employmentRef.employment_type.key', '==', 2)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.employment_type.key', '==', 2)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.employment_type.key', '==', 2)
        }
      }
    }

    if (tabkey == 'academic_instructors') {
      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref
            .where('academicRef.end_date_key', '>', 0)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.end_date_key', '>', 0)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.end_date_key', '>', 0)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where('academicRef.employment_type.key', '==', 1)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.employment_type.key', '==', 1)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.employment_type.key', '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where('academicRef.employment_type.key', '==', 2)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.employment_type.key', '==', 2)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.employment_type.key', '==', 2)
        }

      }
    } else if (tabkey != 'all' && tabkey != 'staff' && tabkey != 'academic_instructors') {
      const em = tabkey + '.employment_type.key';
      const inst = tabkey + '.institute.key';
      const edate = tabkey + '.end_date_key';

      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref.where(edate, '>', 0).where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref.where(edate, '>', 0).where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 1)
            .where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 1)
            .where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey).where(em, '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 2)
            .where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 2)
            .where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey).where(em, '==', 2)
        }

      }
    }
    return ref;
  }
  employeesRef(field: string) {
    return this.db.collection("employees", ref =>
      ref
        .orderBy(field, 'desc')
        .limit(Pages.size)
    );
  }

  compareObject(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.key === o2.key;
    }
    return false;
  }
  //end hr
  studentAccountFireRef() {
    return this.firestore().collection("student_accounts");
  }

  testingDoc(key: string) {
    return this.db.collection("testing").doc(key);
  }

  pettyCashScrollRef(lastVisible: any) {
    return this.db.collection("petty_cash", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }
  pettyCashFireRef() {
    return this.firestore().collection("petty_cash");
  }
  employeeSearchRef(search: any) {
    if (search.key) {
      return this.db.collection("employees", ref => ref
        .where("key", "==", search.key).limit(1)
      );
    }
    return this.db.collection("employees", ref =>
      ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .limit(Pages.size)
    );
  }

  employeeSRef() {
    return this.db.collection("employees", ref => ref.limit(Pages.size));
  }
  testingCollectionRef(user: any, status: string, text) {
    if (text) {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", text)
          .where("create_by.key", "==", user.key)
      );
    }
    let ref = this.db.collection("testing", ref =>
      ref.orderBy("page_key", "desc").limit(Pages.size)
    );
    switch (status) {
      case "all":
        ref = this.db.collection("testing", ref =>
          ref
            // .orderBy("page_key", "desc")
            .where("create_by.key", "==", user.key)
            .limit(Pages.size)
        );
        break;
      case "unpaid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", false)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "paid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", true)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "own":
        ref = this.db.collection("testing", ref =>
          ref
            .where("create_by.key", "==", user.key)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      default:
        break;
    }
    return ref;
  }
  testingCollectionScrollRef(textSearch: string, lastVisible: any) {
    if (textSearch !== "") {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", textSearch)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("testing", ref =>
        ref
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }

  registrationResultFireRef() {
    return this.firestore().collection("registration_result");
  }

  testingResultFireRef() {
    return this.firestore().collection("testing_result");
  }
  testingResultDBRef() {
    return this.db.collection("testing_result");
  }
  studentAdmissionTranscriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }
  testingCenterCollectionRef(termKey, campusKey, programKey, sectionKey, serialNumber: any, lastVisible: any) {
    if (serialNumber) {
      return this.db.collection("academic_year").doc(termKey)
        .collection('campus').doc(campusKey)
        .collection("testing", ref => ref.where("puc_id", "==", serialNumber))
    }

    return this.db.collection("academic_year").doc(termKey)
      .collection("campus").doc(campusKey).
      collection("testing", ref => {
        let condition = ref.where("program.key", "==", programKey)
          .orderBy("create_date", "desc").limit(Pages.size)
        switch (sectionKey) {
          case "campus":
            condition = condition.where("onlineTesting", "==", false)
            break;
          case "online":
            condition = condition.where("onlineTesting", "==", true)
            break;
          case "already_take":
            condition = condition.where("isTakeTest", "==", true)
            break;
          case "unpaid":
            condition = condition.where("isPaid.key", "==", TestFeeStatus.unpaid.key)
            break;
          case "paid":
            condition = condition.where("isPaid.key", "==", TestFeeStatus.paid.key)
            break;
          default:
            break;
        }
        if (lastVisible) {
          condition = condition.startAfter(lastVisible["create_date"])
        }
        return condition;
      })
  }


  testingPermissionCollectionRef(schoolKey, campusKey, statusKey, serialNumber: any, lastVisible: any) {
    if (serialNumber) {
      return this.db.collection("stores").doc(schoolKey)
        .collection('campus').doc(campusKey)
        .collection("permissions", ref => ref.where("student.puc_id", "==", serialNumber))
    }

    if (statusKey !== 'all') {
      return this.db.collection("stores").doc(schoolKey)
        .collection("campus").doc(campusKey).
        collection("permissions", ref => {
          let condition = ref.where("request_status.key", "==", statusKey)
            .orderBy("created_at", "desc").limit(Pages.size)
          if (lastVisible) {
            condition = condition.startAfter(lastVisible["created_at"])
          }
          return condition;
        })
    } else {
      return this.db.collection("stores").doc(schoolKey)
        .collection("campus").doc(campusKey).
        collection("permissions", ref => {
          let condition = ref.orderBy("created_at", "desc").limit(Pages.size)
          if (lastVisible) {
            condition = condition.startAfter(lastVisible["created_at"])
          }
          return condition;
        })
    }
  }


  invoiceSummeryByShiftRef(shiftKey: string, invoiceTypeKey: number, type) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("dailyShift.key", "==", shiftKey)
            .orderBy("page_key", "desc")
        );
        break;
    }
    return ref;
  }

  studentsRef() {
    return this.db.collection('students')
  }
  parentsRef() {
    return this.db.collection("parents");
  }
  parentsDBRef(schoolKey) {
    return this.db.collection("parents", ref => ref.where("schoolKey", "==", schoolKey).limit(100));
  }
  classroomRef() {
    return this.db.collection("classroom", ref => ref.orderBy("name"));
  }


  buildingRef(schoolKey: any, campusKey?: any) {
    return this.db.collection("stores").doc(schoolKey).collection("building", ref => {
      let condition = ref.orderBy("campus.order")
      if (campusKey) {
        condition = ref.where('campus.key', '==', campusKey)
      }
      return condition;
    });
  }

  sessionRef() {
    return this.db.collection("institute_session", ref =>
      ref.orderBy("page_key").limit(100)
    );
  }
  employeeDBRef() {
    return this.db.collection("employees", ref => ref.orderBy("full_name").limit(100));
  }

  issueCardDBRef() {
    return this.storeRef().collection("card_issue_person");
  }
  publicHolidayRef() {
    return this.db.collection("public_holiday", ref => ref.orderBy("from_date_key"));
  }
  trainingBatchLevelRef(levelKey, shiftKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
      .where("shift.key", "==", shiftKey)
      .where("status.key", "==", 1)
    )
  }
  studentNewAdmission(termKey, campusKey, programKey, levelKey) {
    return this.db.collection('academic_year').doc(termKey)
      .collection("campus").doc(campusKey)
      .collection("admission", ref => {
        let condition = ref.where("isFreshman", "==", true)
          .where("isHaveBatch", "==", false)
          .where("trainingProgram.key", "==", programKey)
        if (levelKey) {
          condition = condition.where('trainingGrade.key', '==', levelKey)
        }
        return condition.orderBy('create_date');
      })
  }
  studentNewAdmissionRef(academicYearKey: string, campusKey: string, levelKey: string, field: string, search) {
    if (search) {
      if (search.key) {
        return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
          .where("status.key", "==", 1)
          .where("campus.key", "==", campusKey)
          .where("program_academic.key", "==", levelKey)
          .where("isHaveBatch", "==", false)
          .where("key", "==", search.key)
          .limit(Pages.size)
        );
      }
      return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
        .where("status.key", "==", 1)
        .where("campus.key", "==", campusKey)
        .where("program_academic.key", "==", levelKey)
        .where("isHaveBatch", "==", false)
        .where(field, ">=", search)
        .orderBy(field)
        .orderBy("page_key")
        .limit(Pages.size)
      );
    }
    return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
      .where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelKey)
      .where("isHaveBatch", "==", false)
      .limit(Pages.size)
    );
  }
  instituteTrainingLevelRef() {
    return this.db.collection("institute_training_level")
  }
  batchSessionFireRef(sessionKey: string) {
    return this.firestore().collection("institute_training_level_batch").where("session.key", "==", sessionKey);
  }
  batchStudentAdmissionRef(shiftKey, levelKey, programKey, limit: number) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("admission_type.key", "==", 2)
      .where("program_academic.start_level_key", "==", levelKey)
      .where("program_academic.key", "==", programKey)
      .orderBy("student.full_name")
      .limit(limit)
    )
  }
  batchLevelDocRef(key) {
    return this.db.collection("institute_training_level_batch").doc(key);
  }

  batchLevelKeyRef(levelKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
    )
  }

  batchConfirmLevelKeyRef(academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      // .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
    )
  }
  newEnglishStudentRef(instituteKey: string, campusKey: string, shiftKey: string, programKey: string, levelKey: string, limit: number, termKey: string) {
    return this.db.collection("schedule_in_progress", ref => ref
      .where("institute.key", "==", instituteKey)
      .where("target_term.key", "==", termKey)
      .where("target_campus.key", "==", campusKey)
      .where("study_session.key", "==", shiftKey)
      .where("test_type.key", "==", programKey)
      .where("testing_result.level.key", "==", levelKey)
      .where("isPaid.key", "==", 1)
      .limit(limit)
      .orderBy("student.full_name")
    );
  }
  newAdmissionStudentRef(academicYearKey: string, campusKey: string, levelKey: string, limit: number) {
    return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
      .where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelKey)
      .where("isHaveBatch", "==", false)
      .limit(limit)
    );
  }
  studentInstituteComingBackRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey, limit) {
    return this.db.collection("institute_no_schedule", ref => ref
      .where("program.category.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("subject.key", "==", levelKey)
      .where("isPaid", "==", true)
      .orderBy("student.full_name")
      .limit(limit)
    );
  }
  sessionLevelKeyRef(levelKey, shiftKey) {
    return this.db.collection("institute_training_level_session", ref => ref
      .where("shift.key", "==", shiftKey)
    )
  }
  sessionLevelRef() {
    return this.db.collection("institute_training_level_session")
  }
  instituteFailFireRef() {
    return this.firestore().collection("institute_fail_students")
  }
  removeStudentFromBatchFireRef() {
    return this.firestore().collection("remove_student_from_batch");
  }
  studentEnrollInstituteFireRef() {
    return this.firestore().collection("admission_enrollment_institute_movement");
  }
  studentInstituteNoScheduleFireRef() {
    return this.firestore().collection("institute_no_schedule");
  }
  batchLevelMovementFireRef() {
    return this.firestore().collection("institute_batch_movement")
  }
  instituteNoScheduleStudentFireRef() {
    return this.firestore().collection("institute_no_schedule")
  }
  import_student_to_classFireRef() {
    return this.firestore().collection("import_student_to_class");
  }
  institutesRef() {
    return this.db.collection("institutes", ref =>
      ref.where("instituteType.key", "==", 1)
    );
  }
  termInstituteRef(termType) {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.type", "==", termType.type))
  }
  instituteTermRef(instituteKey) {
    return this.db.collection<any>("academic_year", ref => ref
      .where("institute.key", "==", instituteKey)
      // .orderBy("page_key", "desc")
      .limit(Pages.size / 2)
    );
  }
  sessionByShiftRef(shiftKey: string) {
    return this.db.collection("institute_session", ref => ref
      .where("shift.key", "==", shiftKey));
  }

  sessionByShiftDBRef(schoolKey, shiftKey: string, field: string) {
    return this.db.collection("stores").doc(schoolKey).collection("institute_session", ref => ref
      .where("shift.key", "==", shiftKey)
      .orderBy(field)
      .limit(Pages.size));
  }

  autoSessionByShift(schoolKey, shiftKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("stores").doc(schoolKey).collection("institute_session", ref =>
        ref
          .where("shift.key", "==", shiftKey)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field, "desc")
          .limit(Pages.size)
      );
    }
    return this.db.collection("institute_session", ref =>
      ref
        .where("shift.key", "==", shiftKey)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  classroomByCampusRef(schoolKey, campusKey) {
    return this.db.collection("stores").doc(schoolKey).collection("classroom", ref =>
      ref.where("campus.key", "==", campusKey)
    );
  }
  programLevelRef(programKey) {
    return this.db.collection("testLevel", ref =>
      ref.where("program.key", "==", programKey).orderBy("program_level.key")
    );
  }
  bookByLevelRef(levelKey) {
    return this.db.collection("institute_training_level_books", ref => ref.where("level.key", "==", levelKey));
    // return this.db.collection("institute_training_level_books");
  }
  instituteProgramsRef(instituteKey) {
    return this.db.collection<any>("testType", ref => ref
      .where("institute.key", "==", instituteKey)
      .orderBy("order")
    );
  }
  trainingLevelRef(programKey) {
    return this.db.collection("institute_training_level", ref => ref
      .where("program.key", "==", programKey)
      .orderBy("level.order"));
  }
  termInstituteActiveRef(type, instituteKey) {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.key", "==", type)
      .where("termstatus.key", "<", 3)
      .where("institute.key", "==", instituteKey)
      .orderBy("termstatus.key")
      .orderBy("page_key", "desc"))
  }

  shiftRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order"));
  }
  shiftFirstRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order").limit(2));
  }

  holidayByYearRef(year: number, month: number) {
    if (month === 12) {
      return this.db.collection<any>("public_holiday", ref =>
        ref
          .where("year", ">=", year - 1)
          .where("year", "<=", year)
          .orderBy("year")
      );
    }
    return this.db.collection<any>("public_holiday", ref =>
      ref.where("year", "==", year)
    );
  }
  schoolFeeRef() {
    return this.db.collection("school_fee", ref => ref.orderBy("name"));
  }
  feeCategoryRef() {
    return this.db.collection("fee_categories", ref => ref.orderBy("name"));
  }
  schoolProgramRef() {
    return this.db.collection("school_programs", ref => ref.orderBy("name"));
  }

  schoolRef() {
    return this.db.collection("stores", ref => ref.orderBy("order"));
  }

  schoolFireRef() {
    return this.firestore().collection("stores");
  }

  schoolDocRef() {
    return this.db.collection("stores").doc(this.storeKey)
  }
  schoolFireDocRef() {
    return this.db.firestore.collection("stores").doc(this.storeKey)
  }

  storeDocRef(key) {
    return this.db.collection("stores").doc(key);
  }

  studentMissAttendanceRef(campusKey, date) {
    return this.db.collection('students', ref => ref
      .where("campus.key", "==", campusKey)
      .where("scan_card_date", "<", date)
      .orderBy("scan_card_date", "desc")
    )
  }

  programLevelsRef() {
    return this.db.collection("program_levels", ref => ref.orderBy("name"));
  }
  scholarshipTypeRef() {
    return this.db.collection("scholarship_type", ref => ref.orderBy("name"));
  }
  subjectRef(ref?) {
    return this.db.collection("subjects", ref)
  }
  subjectDBRef() {
    return this.db.collection("subjects", ref => ref.orderBy("name_en"));
  }
  educationLevelRef() {
    return this.db.collection("education_levels", ref => ref.orderBy("order"));
  }
  otherServiceFeeRef() {
    return this.db.collection("admission_service_other");
  }

  examCategoryRef() {
    return this.db.collection("exam_categories", ref => ref.orderBy("order"));
  }

  exerciseRef(subjectKey) {
    return this.db.collection('subject_score').doc(subjectKey).collection('exercises', ref => ref.orderBy("order"))
  }

  subjectScoreByGradeRef(schoolKey, key: string) {
    return this.db.collection("stores").doc(schoolKey).collection("subject_score", ref => ref.where("grade.key", "==", key));
  }

  subjectScoreRef() {
    return this.db.collection("subject_score");
  }
  studentAllAdmissionRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref.where("student.key", "==", key)
    );
  }

  admissionRef() {
    return this.db.collection("academics_major_admission");
  }

  academicGradeRef() {
    return this.db.collection("academic_grade", ref => ref.orderBy("order"));
  }

  shiftDBRef() {
    return this.db.collection("academics_shift");
  }

  trainingSchoolFeeRef(campusKey, levelKey) {
    return this.db.collection("campus").doc(campusKey).collection("training_levels").doc(levelKey).collection("training_school_fee");
  }
  resourceEducationGradeRef() {
    return this.db.collection("resources_education_grades", ref => ref.orderBy("order"));
  }
  resourceEducationSubjectScoreByGradeRef(key: string) {
    return this.db.collection("resources_education_subject_score", ref => ref.where("grade.key", "==", key));
  }
  academicFireRef() {
    return this.firestore().collection("academic_environment").doc("academic_environment");
  }
  campusFireRef() {
    return this.firestore().collection("campus");
  }

  instructorDocRef(key) {
    return this.db.collection("instructors").doc(key);
  }
  receiptWingEPaymentRef(fromDate: number, toDate: number) {
    return this.db.collection("invoices", ref => ref
      .where("payment_type.key", '==', 2)
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", 1)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
      .orderBy("received_date_key", "desc")
    )
  }

  studentInvoiceRef(studentKey) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true));
    // return this.db.collection('students').doc(studentKey).collection<any>("invoices", ref => ref.where("isEnrollVerify", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
  }

  creditNoteFireRef() {
    return this.firestore().collection("credit_note")
  }

  termFireRef() {
    return this.firestore().collection("academic_year");
  }

  termDBRef() {
    return this.db.collection("academic_year");
  }

  instituteScheduleRef() {
    return this.firestore().collection("institute_schedules");
  }

  englishPaidInvoiceRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 2)
      .where("school_fee_type.key", "==", 3)
      .where("target_term.key", "==", termKey)
      .where("isFreshmen", "==", true)
      .where("isHeader", "==", false)
      .where("program.key", "==", "DOIEIbAZ6hjcXLB3p1lo")
      .where("dailyShift.status.key", "==", 1));
  }

  englishPaidInvoiceTestingRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("issue_term.key", "==", termKey)
      .where("isHeader", "==", false)
      .where("program.key", "==", "ysrk2Kb4u65eexcv13Vi")
      .where("dailyShift.status.key", "==", 1));
  }

  paidInvoiceRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 2)
      .where("school_fee_type.key", "==", 1)
      .where("issue_term.key", "==", termKey)
      .where("isHeader", "==", true)
      .where("dailyShift.status.key", "==", 1));
  }

  batchStudentMovementRef() {
    return this.db.collection("institute_student_batch_movement", ref => ref.limit(5000));
  }

  programRef() {
    return this.db.collection<any>("testType", ref => ref.orderBy("name"));
  }

  levelTestTypeRef() {
    return this.db.collection("institute_training_level", ref => ref.orderBy("name"));
  }

  instituteTermDocsRef(instituteKey) {
    return this.db.collection("academic_year", ref => ref
      .where("institute.key", "==", instituteKey)
    )
  }

  batchLevelFireRef() {
    return this.firestore().collection("institute_training_level_batch")
  }

  studentBatchEnrollmentFireRef() {
    return this.firestore().collection("student_batch_enrollment_invoices")
  }

  studentBatchMovementFireRef() {
    return this.firestore().collection("student_batch_movement");
  }

  batchLevelRef() {
    return this.db.collection("institute_training_level_batch")
  }

  studentRegistrationRef() {
    return this.db.collection("student_registration", ref => ref.where("subject_type.key", "==", 2))
  }

  studentRegistrationFireRef() {
    return this.firestore().collection("student_registration")
  }

  employeeUpdateRef() {
    return this.db.collection("employees", ref => ref
      // .where("status.key", "==", 1).limit(1)
      .orderBy("code")
    )
  }

  batchLevelWrongRef() {
    return this.db.collection("institute_training_level_batch", ref => ref.where("level.program.key", "==", "WgANgpmKCDMbF01F0IM2"))
  }

  batchLevelByCampusRef() {
    return this.db.collection("institute_training_level_batch", ref => ref.where("campus.key", "==", "IR9EP8UXpSoSpQuUxWmn"))
  }

  batchLevelDBRef() {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("status.key", "==", 1)
    );
  }

  batchLevelDBTKRef() {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("promoted_date_key", ">=", 20191211)
      .where("promoted_date_key", "<=", 20191216)
      .orderBy("promoted_date_key")
    );
  }

  batchLevelGradingRef() {
    return this.db.collection("institute_training_level_grading");
  }

  batchLevelGradingFireRef() {
    return this.firestore().collection("institute_training_level_grading");
  }

  termAcademicRef() {
    return this.db.collection("academic_year");
  }

  institutePromoteLevelBatchRef(termKey) {
    return this.db.collection("institute_promote_level_batch", ref => ref.where("term.key", "==", termKey));
  }

  invoicePaidRef() {
    return this.db.collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("received_date_key", ">=", 20191030)
      .where("received_date_key", "<=", 20191101)
      .orderBy("received_date_key")
    );
  }

  termAcademicReportRef() {
    return this.db.collection("academic_year", ref => ref.orderBy("startDateKey", "desc").limit(50));
  }

  studentDoc(key) {
    return this.db.collection('students').doc<any>(key)
  }


  scholarship_penaltyRef(key) {
    return this.db.collection<any>("students").doc<any>(key).collection('scholarship_penalty', ref => ref.where('isPaid', '==', false));
  }
  allscholarshipRef(key) {
    return this.db.collection<any>('scholarships', ref => ref
      .where('student.key', '==', key)
    );
  }
  studentTranscriptRef(studentKey, admissionKey) {
    return this.db.collection<any>("academic_student_transcript")
      .doc(studentKey)
      .collection("admission")
      .doc(admissionKey)
      .collection<any>("courses", ref => ref
        .orderBy("term.page_key"));
  }

  studentRef(schoolKey: string, field: string) {
    return this.db.collection("students", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy(field)
      .limit(Pages.size)
    );
  }
  studentTransfer(studentKey: string) {
    return this.db.collection('academics_student_transfer', ref => ref.where("student.key", "==", studentKey))
  }

  studentFetchRef(field: string, search: string, lastVisible: any) {
    if (search) {
      return this.db.collection("students", ref =>
        ref
          .where(field, ">=", search)
          .orderBy("page_key", "desc")
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("students", ref =>
        ref
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }
  trainingFeeRef() {
    return this.db.collection("academic_training_grade_movement");
  }
  admissionDBRef() {
    return this.db.collection("academics_major_admission");
  }
  admissionFireRef() {
    return this.firestore().collection("academics_major_admission");
  }
  admissionStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }
  studentTranscriptFire() {
    return this.firestore().collection('academic_student_transcript')
  }
  studentTransferFire() {
    return this.firestore().collection('academics_student_transfer')
  }
  studentAdmissionTranscriptFire(studentKey) {
    return this.firestore().collection('academic_student_transcript').doc(studentKey).collection("admission")
  }

  studentSearchRef(schoolKey, campusKey, field: string, search: string) {
    if (!search) {
      return this.db.collection("students", ref =>
        ref
          // .where("campus.key", "==", campusKey)
          .where("schoolKey", "==", schoolKey)
          .orderBy(field)
          .limit(Pages.size)
      );
    }
    const text = search.toUpperCase();
    return this.db.collection("students", ref =>
      ref
        // .where("campus.key", "==", campusKey)
        .where("schoolKey", "==", schoolKey)
        .where(field, ">=", text)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  employeeSearchRef0(schoolKey, field: string, search: string) {
    if (!search) {
      return this.db.collection("employees", ref =>
        ref
          .where("schoolKey", "==", schoolKey)
          .orderBy(field)
          .limit(Pages.size)
      );
    }
    const text = search.toUpperCase();
    return this.db.collection("employees", ref =>
      ref
        .where("schoolKey", "==", schoolKey)
        .where(field, ">=", text)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  dailyShiftPaymentDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("shift_movements", ref => ref
      .where("create_by.key", "==", uid)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  dailyTestingRef(fromDate: number, toDate: number, campusKey: string, programKey: string) {
    return this.db.collection('testing', ref => {
      // let fromDateKey = moment(new Date()).startOf('day').toDate();;
      // let toDateKey = moment(new Date()).endOf('day').toDate();
      let condition = ref
        .where("status.key", "==", 1)
        .where('create_date', ">=", fromDate)
        .where('create_date', "<=", toDate)
      // .orderBy('create_date', "desc")
      if (campusKey) {
        condition = condition.where("campus.key", "==", campusKey)
      }
      if (programKey) {
        condition = condition.where("programLevel.program.key", "==", programKey)
      }
      return condition
    })
  }

  scholarshipsListingRef(fromDate: number, toDate: number, campusKey: string, programKey: string) {
    return this.db.collection('scholarships', ref => {
      // let fromDateKey = moment(new Date()).startOf('day').toDate();;
      // let toDateKey = moment(new Date()).endOf('day').toDate();
      let condition = ref
        .where("status.key", "==", 1)
        .where('create_date', ">=", fromDate)
        .where('create_date', "<=", toDate)
      // .orderBy('create_date', "desc")
      if (campusKey) {
        condition = condition.where("campus.key", "==", campusKey)
      }
      if (programKey) {
        condition = condition.where("scholarship.key", "==", programKey)
      }
      return condition
    })
  }


  dailyShiftPaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("shift_movements", ref => ref
        .where("start_cashier.campus.key", "==", campusKey)
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    } else {
      return this.db.collection("shift_movements", ref => ref
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    }

  }

  dailyShiftPaymentCampusRef(campuskey, fromDate: number, toDate: number) {
    return this.db.collection("shift_movements", ref => ref
      .where("start_cashier.campus.key", "==", campuskey)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  installmentPaymentRef(fromDate: number, toDate: number) {
    return this.db.collection("installment_movement", ref => ref
      // .where("received_by.key", "==", uid)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
      .orderBy("received_date_key", "desc")
    );
  }
  expensePaymentDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("createBy.key", "==", uid)
      .where("datePaymentKey", ">=", fromDate)
      .where("datePaymentKey", "<=", toDate)
      .orderBy("datePaymentKey", "desc")
    );
  }
  expensePaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("petty_cash", ref => ref
        .where("campus.key", "==", campusKey)
        .where("datePaymentKey", ">=", fromDate)
        .where("datePaymentKey", "<=", toDate)
        .orderBy("datePaymentKey", "desc")
      );
    } else {
      return this.db.collection("petty_cash", ref => ref
        .where("datePaymentKey", ">=", fromDate)
        .where("datePaymentKey", "<=", toDate)
        .orderBy("datePaymentKey", "desc")
      );
    }
  }

  matchTermPaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("student_align_payment", ref => ref
        .where("campus.key", "==", campusKey)
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    } else {
      return this.db.collection("student_align_payment", ref => ref
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    }
  }

  receiptReportByTermRef(fromDate: number, toDate: number, termKey: any, cashierKey: any) {
    let ref = this.db.collection("invoices", ref => {
      let colWhere = ref
        .where("isHeader", "==", true)
        .where("dailyShift.status.key", "==", 1)
        .where("isPaid.key", "==", paymentStatus.paid.key)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      if (cashierKey) {
        colWhere = colWhere.where("received_by.key", "==", cashierKey)
      }
      if (termKey) {
        colWhere = colWhere.where("issue_term.key", "==", termKey)
      }

      return colWhere.orderBy("received_date_key", "desc")
    });

    return ref;
  }

  receiptReportDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", 1)
      // .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
      .where("received_by.key", "==", uid)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
    );
  }

  receiptReportRef(fromDate: number, toDate: number, termKey: any, cashierKey: any) {

    if (termKey && !cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("issue_term.key", "==", termKey)
      );
    } else if (!termKey && cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    } else if (termKey && cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("issue_term.key", "==", termKey)
      );
    } else {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    }

  }

  receiptSummaryReportRef(fromDate: number, toDate: number, termKey: any, cashierKey: any, programKey: any) {

    if (termKey && !cashierKey && !programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("issue_term.key", "==", termKey)
      );
    } else if (!termKey && cashierKey && !programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("received_by.key", "==", cashierKey)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    } else if (termKey && !cashierKey && programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", programKey)
      );
    } else if (termKey && cashierKey && programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", programKey)
      );
    } else {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    }

  }

  userRef(uid: string) {
    return this.db.collection('users').doc<any>(uid);
  }

  userDBRef() {
    return this.db.collection('users');
  }

  userFireSRef(uid: string) {
    return this.firestore().collection('users').doc(uid);
  }

  gradeSheetStudentBatchRef(batchKey, monthKey, gradeSheetTypeKey) {
    return this.db.collection("grade_sheet_batches", ref => {
      let condition = ref.where("batchKey", '==', batchKey)
      if (gradeSheetTypeKey < 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
      }
      if (gradeSheetTypeKey === 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
      }
      if (gradeSheetTypeKey === 1) {
        condition = condition.where("gradeSheet.month.key", '==', monthKey)
      }
      return condition.orderBy('result', 'desc')
    });
  }


  gradeSheetStudentBatchMovementRef(batchKey, monthKey, gradeSheetTypeKey) {
    return this.db.collection("grade_sheet_student_movements", ref => {
      let condition = ref.where("batchKey", '==', batchKey)

      if (gradeSheetTypeKey < 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
      }
      if (gradeSheetTypeKey === 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
      }

      if (gradeSheetTypeKey === 1) {
        condition = condition.where("gradeSheet.month.key", '==', monthKey)
      }
      return condition.orderBy('result', 'desc')
    });
  }

  gradeSheetsRef(batchKey) {
    return this.db.collection("grade_sheet_movements", ref => ref
      .where("batchKey", '==', batchKey)
    );
  }

  gradeSheetsFireRef() {
    return this.firestore().collection("grade_sheet_movements");
  }

  gradeSheetsApprovedFireRef() {
    return this.firestore().collection("grade_sheet_movements_approved");
  }

  campusRef() {
    return this.db.collection("campus", ref => ref.orderBy("order"));
  }


  campusStoreRef(key) {
    return this.db.collection('stores').doc(key).collection("campus", ref => ref.orderBy("order"));
  }

  campusFirstRef() {
    return this.db.collection("campus", ref => ref.orderBy("order").limit(1));
  }

  schoolProgramsRef() {
    return this.db.collection("school_programs", ref => ref.orderBy("name"));
  }
  collectionDocRef(collectionName) {
    return this.firestore().collection(collectionName);
  }

  creditNoteRef(type: any) {
    if (type === 'all') {
      return this.db.collection('credit_note');
    } else if (type === 'paid') {
      return this.db.collection('credit_note', ref => ref.where('isPaid', '==', true));
    } else if (type === 'unpaid') {
      return this.db.collection('credit_note', ref => ref.where('isPaid', '==', false));
    }
  }

  shiftMovementDocRef(fromDate, toDate) {
    return this.db.collection('shift_movements', ref => ref
      .where("create_date_key", '>=', fromDate)
      .where("create_date_key", '<=', toDate)
    );
  }

  shiftMovementDBRef(key) {
    return this.db.collection("shift_movements").doc<any>(key);
  }

  userDocRef(uid: string) {
    return this.db.collection("users").doc<any>(uid);
  }

  settingDBFireStore() {
    return this.db.collection("testing_options").doc("general");
  }
  paymentOptionRef() {
    return this.db.collection("payment_options", ref => ref.orderBy("order"));
  }
  academicEnvRef() {
    return this.db.collection("academic_environment");
  }

  invoiceCashierRef(user: any) {
    return this.db.collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", user.key)
        .where("dailyShift.key", "==", user.shift.key)
        .orderBy("received_date_key")
    );
  }

  shiftSummaryFireRef() {
    return this.firestore().collection("shift_summary");
  }

  campusDocRef(doc) {
    return this.db.collection('campus').doc(doc);
  }

  cashierRef() {
    return this.db.collection('users', ref => ref.where('role.key', '==', 3));
  }



  lazyUserRef(lastVisible: any, search, filter) {
    return this.db.collection<any>("users", ref => {
      let condition = ref
        .where("status.key", "==", 1)
        .orderBy("update_date", "desc")
        .limit(Pages.size)
      if (search) {
        const txt = ConvertService.toCapitalize(search)
        condition = condition.where('keywords', 'array-contains', txt)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }

  lazyCashierRef(lastVisible: any, search, filter) {
    return this.db.collection<any>("users", ref => {
      let condition = ref
        .where("status.key", "==", 1)
        .where('role.key', '==', 3)
        .orderBy("update_date", "desc")
        .limit(Pages.size)
      if (search) {
        const txt = ConvertService.toCapitalize(search)
        condition = condition.where('keywords', 'array-contains', txt)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }
  cashierInvoiceRef(uid, invoice_type, fromDate, toDate) {
    switch (invoice_type) {
      case 0:
        return this.db.collection<any>('invoices', ref => ref
          .where('received_by.key', '==', uid)
          .where("received_date_key", '>=', fromDate)
          .where("received_date_key", '<=', toDate)
          .orderBy("received_date_key")
          .orderBy('invoice_no'));
      default:
        return this.db.collection<any>('invoices', ref => ref
          .where('received_by.key', '==', uid)
          .where("received_date_key", '>=', fromDate)
          .where("received_date_key", '<=', toDate)
          .orderBy("received_date_key")
          .orderBy('invoice_no'));
        break;
    }

    // return this.db.collection('invoices', ref => ref.where('issue_by.key', '==', userkey).where('role.key', '==', 3).where('invoice_type.key', '==', invoice_type).orderBy('issue_date'));
  }
  cashierDocRef(key) {
    return this.db.collection('users').doc(key);
  }

  sysProgramRef() {
    return this.db.collection("sys_programs", ref => ref
      .where("active", "==", true)
    )
  }
  termEnvironmentRef() {
    return this.firestore().collection("academic_environment").doc("academic_environment")
  }
  studentCurrentScheduleRef(termKey, studentKey) {
    return this.db
      .collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
        .where("isHeader", "==", false)
        .where("isVoid", "==", false)
      );
  }
  academicEnvironmentFireDocRef() {
    return this
      .firestore()
      .collection("academic_environment")
      .doc("academic_environment");
  }
  academicRef() {
    return this.storeRef().collection("academic_environment").doc("academic_environment");
  }
  activeTermRef() {
    return this.db.collection('academic_year', ref => ref
      .where("status.key", "==", 2));
  }
  scholarshipReportRef(programkey, programtype, start, end, term) {
    let ref = this.firestore().collection('scholarships').where('date_key', '>=', Number(start)).where('date_key', '<=', Number(end)).where('enroll_term.key', '==', term.key).where('scholarship_type.key', '==', programtype.key)
    if (programkey != 'all') {
      return ref.where('program.key', '==', programkey)
    }
    return ref;
  }

  allscholarshipTypeRef(type, program) {
    if (program) {
      return this.db.collection<any>('scholarships', ref => ref.where('scholarshipType.key', '==', type).where('program.route', '==', program));
    } else {
      return this.db.collection<any>('scholarships', ref => ref.where('scholarshipType.key', '==', type));
    }
  }
  batch() {
    return this.db.firestore.batch();
  }
  invoiceFireRef() {
    return this.firestore().collection("invoices");
  }
  academicsShiftRef() {
    return this.db.collection("academics_shift");
  }
  studentFireDocument(key: string) {
    return this.firestore().collection("students").doc(key);
  }

  paymentDiscountRef() {
    return this.db.collection("payment_discount", ref => ref.where("status.key", "==", 1));
  }

  paymentDiscountDBRef() {
    return this.db.collection("payment_discount", ref => ref.orderBy("updated_at", "desc"));
  }

  studentDocument(key: string) {
    return this.db.collection("students").doc<any>(key);
  }

  student_profile_history_movement() {
    return this.db.collection("student_profile_history_movement");
  }

  studentSibling(studentKey: string) {
    return this.db.collection("students_sibling", ref => ref.where("studentKey", "array-contains", studentKey));
  }

  studentSiblingRef() {
    return this.db.collection("students_sibling");
  }

  studentTestRef(studentKey) {
    return this.db.collection("testing", ref =>
      ref
        .where("student.key", "==", studentKey)
        .orderBy("create_date", "desc")
        .limit(1)
    );
  }
  testingStudent(key: string) {
    return this.db.collection("testing").doc(key);
  }
  studentAdmissionRef() {
    return this.db.collection("academics_major_admission");
  }

  studentAdmissionDBRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref
        .where("student.key", "==", key)
      // .orderBy("program.key", "desc")
      // .limit(1)
    );
  }
  scholarshipByStudent(Key) {
    return this.db.collection("scholarships", ref => ref.where("student.key", "==", Key));
  }
  admissionDocRef(admissionKey) {
    return this.db.collection<any>("academics_major_admission").doc<any>(admissionKey)
  }

  testingDBRef() {
    return this.db.collection("testing");
  }
  studentBatchMovement(termKey: string) {
    return this.db.collection("student_batch_movement", ref => ref.where("term.key", "==", termKey));
  }

  studentListByPUCID(id: string) {
    return this.db.collection("students", ref => ref
      .where("puc_id", ">=", id)
      .orderBy("puc_id", "desc")
      // .limit(10)
    );
  }

  studentPUCID(puc_id: string) {
    return this.db.collection("students", ref => ref.where("puc_id", "==", puc_id));
  }
  installmentFireRef() {
    return this.firestore().collection("installment_movement");
  }
  academicYearFireRef() {
    return this.firestore().collection("academic_year");
  }

  admissionDocFireRef(admissionKey) {
    return this.firestore().collection("academics_major_admission").doc(admissionKey)
  }

  academicYearRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.orderBy("startDate", "desc").limit(30));
  }

  academicYearDocRef(key) {
    return this.db.collection("academic_year").doc(key);
  }

  academicYearByTypeRef(termTypeKey) {
    return this.db.collection("academic_year", ref => ref.where('termType.key', '==', termTypeKey).orderBy('startDate').limit(30));
  }

  academicYearDBRef() {
    return this.db.collection("academic_year", ref => ref.orderBy("startDate", "desc"));
  }

  studentAdmissionFireRef() {
    return this.firestore().collection("academics_major_admission");
  }
  studentFireRef() {
    return this.firestore().collection("students");
  }
  invoiceFailFireRef() {
    return this.firestore().collection("invoice_fail");
  }
  employeeFireRef() {
    return this.firestore().collection("employees");
  }
  employeeRef() {
    return this.db.collection("employees");
  }
  employeeDocRef(key) {
    return this.db.collection("employees").doc(key);
  }
  instituteSessionRef() {
    return this.db.collection("institute_training_level_session", ref => ref.orderBy("name"));
  }
  studentAdmissionEnDocRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("student.key", "==", studentKey)
      .where("admission_type.key", "==", 2)
    )
  }
  batchLevelShiftRef(levelKey, shiftKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("shift.key", "==", shiftKey)
      .where("campus.key", "==", campusKey)
      .orderBy("name")
    )
  }
  transcriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }
  instituteNoScheduleRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey));
  }
  admissionByStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }
  instituteNoScheduleStudentRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey))
  }
  scheduleInProgressStudentRef(studentKey) {
    return this.db.collection("schedule_in_progress", ref => ref.where("student.key", "==", studentKey))
  }
  scheduleInProgressFireRef() {
    return this.firestore().collection("schedule_in_progress")
  }
  userFireRef() {
    return this.firestore().collection("users");
  }

  dailyShiftFireRef() {
    return this.firestore().collection("shift_movements");
  }
  instructorSearchRef(instituteKey, field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        // .where(`${instituteKey}.institute.key`, '==', instituteKey)
        .where(field, ">=", search)
        .orderBy("full_name")
        .orderBy("page_key")
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      // .where(`${instituteKey}.institute.key`, '==', instituteKey)
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  instructorRef() {
    return this.db.collection("instructors");
  }
  academicFirebaseRef() {
    return this.db
      .collection("academic_environment")
      .doc("academic_environment");
  }
  academicGrade() {
    return this.db.collection("academic_grade", ref => ref.orderBy("name"));
  }

  instituteInstructorRef(schoolKey, search) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        .where("schoolKey", '==', schoolKey)
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      .where("schoolKey", '==', schoolKey)
      .orderBy("full_name")
      .limit(Pages.size)
    )
  }

  gradeSystemProgramRef(programKey) {
    return this.db.collection("institute_grade_system", ref => ref.where('programKey', '==', programKey));
  }

  gradeSystemRef() {
    return this.db.collection("institute_grade_system");
  }




  classManagerFetchRef(
    programType: number,
    termKey: string,
    scheduleType: number,
    lastVisible: any
  ) {
    return this.db.collection<any>("academics_schedules", ref =>
      ref
        .where("isMaster", "==", true)
        .where("masterMajor.program.key", "==", programType)
        .where("scheduleType.key", "==", scheduleType)
        .where("term.key", "==", termKey)
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }

  employeeListRef(schoolKey) {
    return this.db.collection("employees", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy("full_name")
      .limit(20));
  }

  instructorsListRef() {
    return this.db.collection("instructors", ref => ref.orderBy('full_name').limit(20));
  }
  englishClassroomSearchRef(schoolKey: string, field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        .where("schoolKey", "==", schoolKey)
        .where(field, ">=", search)
        .orderBy(`${field}`)
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy("full_name")
      .limit(Pages.size)
    )
  }
  academicClassroomSearchRef(field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("instructors", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("instructors", ref => ref
        .where(field, ">=", search)
        .orderBy(`${field}`)
        .limit(Pages.size)
      )
    }
    return this.db.collection("instructors", ref => ref
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  // studentInvoiceByAdmission(admissionKey: string, studentKey: string) {
  //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
  //     // .where("program.admissionKey", "==", admissionKey)
  //     // .where("isEnrollVerify", "==", true)
  //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
  //   );
  // }

  studentInvoiceByAdmission(admissionKey: string, studentKey: string, invoiceKey) {
    if (invoiceKey) {
      return this.db.collection("students")
        .doc(studentKey)
        .collection("invoices", ref => ref
          .where("headerRef", "==", invoiceKey)
          .where("isPaid.key", "==", paymentStatus.unpaid.key)
        );
    }
    else {
      return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("isEnrollVerify", "==", true)
        .where("isPaid.key", "==", paymentStatus.unpaid.key)
        .where("program.admissionKey", "==", admissionKey)
        .orderBy("page_key")
      );
    }

    // if (admissionKey) {
    //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
    //     .where("isEnrollVerify", "==", true)
    //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
    //     .where("program.admissionKey", "==", admissionKey)
    //     .orderBy("page_key")
    //   );
    // } else {
    //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
    //     .where("isEnrollVerify", "==", true)
    //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
    //     .orderBy("page_key")
    //   );
    // }

  }

  studentTestingRef(studentKey) {
    return this.db.collection("testing", ref => ref
      .where("student.key", "==", studentKey));
  }

  studentTestingInvoice(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", invoiceTypesObj.registrationFee.key)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
    );
  }
  invoiceAllByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
  }

  miscellaneousFeeRef() {
    return this.db.collection("admission_services", ref => ref.where("program.name", "==", "Miscellaneous"));
  }

  paymentTermRef(key: string) {
    return this.db.collection("academic_environment").doc(key);
  }

  invoiceRef(Id: string, status: string, currentDate: number) {
    if (status === "testing-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref.where("student.serial_id", "==", Id).where("isVoid", "==", false)
      );
    } else if (status === "abc-course") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
      );
    } else if (status === "tuition-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          // .where('invoice_type.key', '==', invoiceTypesObj.tuitionFee.key)
          .orderBy("page_key", "desc")
      );
    }
  }

  invoiceItemsRef(studentKey: string, headerRef: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref.where("headerRef", "==", headerRef)
    );
  }
  studentScholarshipRef(Id: string) {
    return this.db.collection<any>("students", ref =>
      ref.where("puc_id", "==", Id).limit(1)
    );
  }
  receivedPaymentFireRef() {
    return this.firestore().collection("received_payment_movement");
  }
  invoicesFireRef() {
    return this.firestore().collection("invoices");
  }

  invoicesReportRef() {
    //Academic Invoice
    // return this.db.collection("invoices", ref => ref.where("invoice_type.key", "==", 2).where("school_fee_type.key", "==", 1).where("isPaid.key", "==", 1).where("isHeader", "==", true));
    //English Invoice
    return this.db.collection("invoices", ref => ref.where("invoice_type.key", "==", 2).where("school_fee_type.key", "==", 3).where("isPaid.key", "==", 1).where("isHeader", "==", true));
  }

  invoicesEnglishTestingProgramRef(termKey?: string) {
    return this.db.collection('academic_year').doc(termKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  invoicesEnglishTestingProgramByCampusRef(termKey: string, campusKey: string) {
    return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  EnglishTestingProgramRef(termKey?: string) {
    return this.db.collection('academic_year').doc(termKey).collection("testing");
  }

  EnglishTestingProgramByCampusRef(termKey: string, campusKey: string) {
    return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("testing");
  }

  invoicesEnglishProgramRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
    }
  }
  invoicesEnglishStudentNewRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('isFreshman', '==', true));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('isFreshman', '==', true));
    }
  }
  invoicesEnglishStudentOldRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('isFreshman', '==', false));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('isFreshman', '==', false));
    }
  }


  invoicesEnglishStudentUnpaidRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('paymentPeriod', '<', 12));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('paymentPeriod', '<', 12));
    }
  }

  invoicesEnglishTestingRef() {
    const termKey = "f1XyZmFRGjlUbtc8KQ84";
    return this.db.collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  registrarFireRef() {
    return this.firestore().collection("registrar_payment");
  }
  studentPucProfileFireRef() {
    return this.firestore().collection("student_puc_profile");
  }

  studentByCampusRef() {
    return this.db.collection("students", ref => ref
      .where("campus.key", "==", "qh0LUi9E0jZB5cduWLJA")
      .limit(490)
    );
  }

  studentAccountRef() {
    return this.db.collection("student_accounts", ref => ref
      .where("status.text", "==", "Actives")
      .limit(490)
    );
  }
  studentAccountFire() {
    return this.firestore().collection("student_accounts");
  }
  studentAccountDocFire() {
    return this.firestore().collection("student_accounts");
  }

  studentAccountDoc(key) {
    return this.db.collection("student_accounts", ref => ref.where("student.key", "==", key));
  }

  studentAccID(pucId) {
    return this.db.collection("students", ref => ref.where("puc_id", "==", pucId));
  }

  studentAccountDocRef(key: string) {
    return this.db.collection("student_accounts").doc<any>(key);
  }
  studentCurrentScheduleTermRef(termKey, studentKey) {
    return this.db.collection("academic_year").doc(termKey).collection("schedules", ref => ref
      .where("enrollmentStudentKey", "array-contains", studentKey)
      .orderBy("schedule_subject.subject.name")
    );
  }
  scheduleInProgressRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey) {
    return this.db.collection("schedule_in_progress", ref => ref
      .where("institute.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("testing_result.level.key", "==", levelKey)
      .orderBy("student.full_name")
    );
  }
  studentInstituteNoScheduleRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey) {
    return this.db.collection("institute_no_schedule", ref => ref
      .where("program.category.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("subject.key", "==", levelKey)
      .where("isPaid", "==", true)
      .orderBy("student.full_name")
    );
  }
  instituteProgramRef(instituteKey) {
    return this.db.collection("testType", ref => ref.where("institute.key", "==", instituteKey))
  }
  academicQuitStudentsFireRef() {
    return this.firestore().collection("academic_quit_students");
  }
  abcStudentRef() {
    return this.firestore().collection("abc_students");
  }

  prepaidFireRef() {
    return this.firestore().collection("prepaid_movement");
  }
  shiftMovementFireRef() {
    return this.firestore().collection("shift_movements");
  }
  testingFireRef() {
    return this.firestore().collection("testing");
  }
  expiredFeeDate(admisionDate: Date, config: TestOption) {
    return moment(admisionDate)
      .add(config.expired_fee_peroid, "days")
      .toDate();
  }
  invoiceItemsByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", paymentStatus.paid.key)
        .orderBy("create_date", "desc")
        .limit(1)
    );
  }

  invoiceItemsByInvoiceNoRef(invoiceNo: number) {
    return this.db.collection<any>("invoices", ref =>
      ref
        .where("invoice_no", "==", invoiceNo)
        .where("isHeader", "==", true)
        // .orderBy("page_key", "desc")
        .limit(1)
    );
  }

  getInvoiceHeaderRef(studentKey: string, admissionKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("program.admissionKey", "==", admissionKey)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
      .orderBy("create_date", "desc")
      .limit(1));
  }
  instituteActiveTermRef(key: string) {
    return this.db.collection("academic_environment").doc<any>(key);
  }
  settingFireStore() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }
  sysSetting() {
    return this.db.collection("testing_options").doc("general");
  }
  storeConfig() {
    return this.db.collection("stores").doc(this.storeKey).collection('sys_option').doc('general');
  }
  invoiceTestingRef(Id: string) {
    return this.db.collection<any>('invoices', ref => ref.where('student.serial_id', '==', Id))
  }
  createId() {
    return this.db.createId();
  }
  shiftMovementRef() {
    return this.db.collection('shift_movements', ref => ref.where("status.key", "==", 2));
  }

  shiftMovementSummaryRef() {
    return this.db.collection('shift_summary', ref => ref.where("allocated_status.key", "<", 2));
  }
  shiftMovementSummaryByCampusRef(campusKey) {
    return this.db.collection('shift_summary', ref => ref.where('campus.key', '==', campusKey).where("allocated_status.key", "<", 2));
  }
  shiftMovementSummaryDocRef(doc) {
    return this.db.collection('shift_summary', ref => ref.where("allocated_status.key", "<", 2)).doc(doc);
  }

  shiftMovementFirRef() {
    return this.firestore().collection('shift_movements');
  }
  shiftMovementSummaryFirRef() {
    return this.firestore().collection('shift_summary');
  }

  instituteRef() {
    return this.db.collection("institutes", ref => ref.orderBy("page_key"));
  }
  termReportRef() {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.key", "==", 2)
      .orderBy("page_key", "desc").limit(10)
    );
  }

  fetchtermRef() {
    return this.db.collection('academic_year', ref => ref
      // .orderBy('page_key', 'desc')
      .limit(12))
  }

  programByInstituteRef(key) {
    return this.db.collection<any>("testType", ref =>
      ref.where("institute.key", "==", key)
        .orderBy("shortName")
    );
  }

  getTestOptions() {
    return this.db.collection("testing_options").doc<any>("general");
  }

  getTestOptionFireSRef() {
    return this.firestore().collection("testing_options").doc("general");
  }

  firestore() {
    return this.db.firestore;
  }

  invoiceByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .orderBy("page_key", "desc")
    );
  }

  invoiceByDetailShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", false)
        .orderBy("page_key", "desc")
    );
  }

  invoiceAllSummeryByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("dailyShift.key", "==", shiftKey)
    );
  }

  pettyCashByShiftRef(shiftKey: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("shift.key", "==", shiftKey)
      .orderBy("page_key", "desc")
    );
  }

  installmentByShiftRef(shiftKey: string) {
    return this.db.collection("installment_movement", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("isInstallment", "==", true)
      .orderBy("page_key", "desc")
    );
  }

  shiftMovementDocsRef() {
    return this.db.collection("shift_movements");
  }

  invoiceTypeListingByShiftRef(shiftKey: string, type: string) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "tuition_fee":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "testing":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.testing.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "abc":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "miscellaneous":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.miscellaneous.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        break;
    }
    return ref;
  }

  paidcourseListingRef(fromDate, toDate, term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("enroll_date_key", ">=", fromDate)
      .where("enroll_date_key", "<=", toDate)
      .where("isPaid", "==", true)
    )
  }

  unpaidcourseListingRef(fromDate, toDate, term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("enroll_date_key", ">=", fromDate)
      .where("enroll_date_key", "<=", toDate)
      .where("isPaid", "==", false)
    )
  }


  unpaidcourseListingTermRef(term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("isPaid", "==", false)
    )
  }


  testingListingRef(fromDate, toDate, institute, term, program, isAll, status) {
    switch (status.key) {
      case "puc_students":
        if (isAll) {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("isPaidTest", "==", true)
            .where("testing_result.status.key", "==", 1)
            .where("isPaid.key", "==", TestFeeStatus.paid.key)
            .orderBy("admission_date_key")
          );
        }
        else {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("isPaidTest", "==", true)
            .where("testing_result.status.key", "==", 1)
            .where("isPaid.key", "==", TestFeeStatus.paid.key)
            .where("test_type.key", "==", program.key)
            .orderBy("admission_date_key")
          );
        }


      default:
        if (isAll) {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .orderBy("admission_date_key")
          );
        }
        else {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("test_type.key", "==", program.key)
            .orderBy("admission_date_key")
          );
        }
    }

  }

  cashierOnlineRef() {
    return this.db.collection('users', ref => ref.where('isShiftOpen', '==', true).where('role.key', '==', 3).orderBy('name'));
  }

  cashierOnlineCampusRef(campusKey) {
    return this.db.collection('users', ref => ref.where('campus.key', '==', campusKey).where('isShiftOpen', '==', true).where('role.key', '==', 3).orderBy('name'));
  }

  termRef() {
    return this.db.collection('term', ref => ref.orderBy('startDateKey', 'desc').limit(Pages.size));
  }
  termAcademicActive() {
    // tslint:disable-next-line:max-line-length
    return this.db.collection('term', ref => ref.where('termstatus.key', '==', 2).where('institute.instituteType.key', '==', 2).orderBy('startDateKey').limit(1));
  }

  termSearchRef(value) {
    const { key } = value;
    if (key) {
      return this.db.collection('academic_year', ref => ref
        .where("key", "==", key)
        .limit(Pages.size))
    }
    return this.db.collection('academic_year', ref => ref
      .where("name", ">=", value)
      // .orderBy('name', "desc")
      .limit(Pages.size))
  }

  academicEnvironmentRef() {
    this.db.collection("shift_movements", ref => ref
      .where("allocated_status.key", "<", 3)
      .orderBy("allocated_status.key")
      .where("create_by.campus.key", "==", '1p7qRJAoJt4BKv4Iiuu4')
    ).valueChanges().subscribe(docs => {
    })

    return this.db
      .collection("academic_environment")
      .doc<any>("academic_environment");
  }
  studentInfo(student) {
    return {
      name: ConvertService.toNull(student.nameEng),
      khmer: ConvertService.toNull(student.nameKh),
      phone: ConvertService.toNull(student.mobile_phone),
      code: ConvertService.toNull(student.puc_id),
      gender: ConvertService.toNull(student.gender),
      key: ConvertService.toNull(student.key),
      email: ConvertService.toNull(student.email)
    };
  }

  employeeInfo(employee) {
    if (employee) {
      return {
        key: ConvertService.toNull(employee.key),
        first_name: ConvertService.toNull(employee.first_name),
        last_name: ConvertService.toNull(employee.last_name),
        full_name: ConvertService.toNull(employee.full_name),
        mobile_phone: ConvertService.toNull(employee.mobile_phone),
        code: ConvertService.toNull(employee.code),
        gender: ConvertService.toNull(employee.gender),
        email: ConvertService.toNull(employee.email),
        nationality: ConvertService.toNull(employee.nationality),
        fileUrl: ConvertService.toNull(employee.fileUrl),
        employee_type: ConvertService.toNull(employee.employee_type),
      };
    } else return null;
  }

  departmentInfo(department) {
    return {
      name: ConvertService.toNull(department.name),
      shortcut: ConvertService.toNull(department.shortcut),
      key: ConvertService.toNull(department.key),
      building: ConvertService.toNull(department.building),
      campus: ConvertService.toNull(department.campus),
      floor: ConvertService.toNull(department.floor),
    };
  }

  officeInfo(office) {
    return {
      name: ConvertService.toNull(office.name),
      key: ConvertService.toNull(office.key),
      employee: ConvertService.toNull(office.employee),
      department: ConvertService.toNull(office.department),
    };
  }

  campusInfo(campus) {
    return {
      name: ConvertService.toNull(campus.name),
      key: ConvertService.toNull(campus.key),
    };
  }
  buildingInfo(building) {
    return {
      name: ConvertService.toNull(building.name),
      key: ConvertService.toNull(building.key),
    };
  }
  floorInfo(floor) {
    return {
      name: ConvertService.toNull(floor.name),
      key: ConvertService.toNull(floor.key),
    };
  }

  countryInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  // nationalityInfo(item) {
  //   if (item) {
  //     return {
  //       name: ConvertService.toNull(item.name),
  //       key: ConvertService.toNull(item.key),
  //     };
  //   } else return null;
  // }
  nationalityInfo(item) {
    if (item) {
      return {
        nationality: ConvertService.toNull(item.nationality),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  positionInfo(item) {
    if (item) {
      return {
        shortcut: ConvertService.toNull(item.shortcut),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }
  userInfo(item) {
    if (item) {
      return {
        name: ConvertService.toNull(item.full_name),
        full_name: ConvertService.toNull(item.full_name),
        email: ConvertService.toNull(item.email),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }



  cityInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }
  districtInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  semployeeInfo(employee) {
    if (employee) {
      return {
        key: ConvertService.toNull(employee.key),
        first_name: ConvertService.toNull(employee.first_name),
        last_name: ConvertService.toNull(employee.last_name),
        full_name: ConvertService.toNull(employee.full_name),
        mobile_phone: ConvertService.toNull(employee.mobile_phone),
        code: ConvertService.toNull(employee.code),
        gender: ConvertService.toNull(employee.gender),
        email: ConvertService.toNull(employee.email),
        nationality: ConvertService.toNull(employee.nationality),
        fileurl: ConvertService.toNull(employee.fileurl),
        employee_type: ConvertService.toNull(employee.employee_type),
      };
    } else return null;
  }

  facultyDocsRef() {
    return this.db.collection("faculties", ref =>
      ref.orderBy("name")
    );
  }

  searchStudentRef(schoolKey: string, field: string, search: string) {
    return this.db.collection("students", ref => {
      let condition = ref
        .where("schoolKey", "==", schoolKey)
        .limit(Pages.size)
      if (search) {
        const text = search.toUpperCase();
        condition = condition.where(field, ">=", text)
      }
      return condition;
    })
  }

  searchInstructorRef(schoolKey: string, field: string, search: string) {
    return this.db.collection("employees", ref => {
      let condition = ref
        .where("schoolKey", "==", schoolKey)
        .limit(Pages.size)
      if (search) {
        const text = search.toUpperCase();
        condition = condition.where(field, ">=", text)
      }
      return condition;
    })

  }

  fetchAllApprovedPermissionsRef(schoolKey: string, campusKey: string, fromDateKey: number, toDateKey: number) {
    return this.db.collection("stores").doc(schoolKey)
      .collection("campus").doc(campusKey).collection("permissions", ref => {
        let condition = ref.where("date_key", '>=', fromDateKey).where("date_key", '<=', toDateKey);
        return condition;
      })
  }

  fetchCardIssuePersonRef() {
    return this.db.collection('stores').doc(this.storeKey).collection('card_issue_person');
  }


}



